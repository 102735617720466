<template>
  <div id="RankList" class="view-RankList">
    <div class="word-box">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="昨日排行" name="first"></el-tab-pane>
        <el-tab-pane label="今日排行" name="second"></el-tab-pane>
        <el-tab-pane label="总排行" name="third"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="RankList-tab-box-wrap">
      <div class="RankList-tab-box" v-if="activeName == 'first'">天下英豪</div>
      <div class="RankList-tab-box" v-else-if="activeName == 'second'">
        群雄逐鹿
      </div>
      <div class="RankList-tab-box" v-else>问鼎天下</div>
    </div>
    <div
      class="timebox"
      v-if="
        this.showtype.days_open == '1' &&
        this.showtype.weeks_open == '1' &&
        (this.activeName == 'second' || this.activeName == 'third')
      "
    >
      <div class="timetext">开奖时间:</div>
      <el-statistic
        class="timenum"
        format="DD天HH小时mm分钟ss秒"
        :value="drawTime"
        time-indices
      >
      </el-statistic>
    </div>
    <div class="timebox" v-else>
      <p class="timetext">排行榜已截止</p>
    </div>
    <div class="avatar-box-wrap">
      <div class="refsbox">
        <div class="refs point" @click="updata">
          <img
            src="../../assets/images/kaijiang/reficon.png"
            :class="restStatus ? 'restAni' : ''"
            alt=""
          />
          <div class="reftext">刷新</div>
        </div>
      </div>
      <div class="sort-box-top">
        <div class="img-list">
          <div class="img-item img-item2">
            <div class="header-img header-img2">
              <img
                class="img1"
                src="../../assets/images/public/top2.png"
                alt=""
              />
              <img class="img2" :src="No2.user.avatar" alt="" />
            </div>
            <div class="header-name">
              {{ No2.user.name || "虚位以待" }}
              <div class="block4">
                <Hotprice
                  :size="0.12"
                  :isWeight="false"
                  :price="No2.win || No2.total_win || 0"
                />
              </div>
            </div>
          </div>
          <div class="img-item img-item1">
            <div class="header-img header-img1">
              <img
                class="img1"
                src="../../assets/images/public/top1.png"
                alt=""
              />
              <img class="img2" :src="No1.user.avatar" alt="" />
            </div>
            <div class="header-name">
              {{ No1.user.name || "虚位以待" }}
              <div class="block4">
                <Hotprice
                  :size="0.12"
                  :isWeight="false"
                  :price="No1.win || No1.total_win || 0"
                />
              </div>
            </div>
          </div>
          <div class="img-item img-item3">
            <div class="header-img header-img3">
              <img
                class="img1"
                src="../../assets/images/public/top3.png"
                alt=""
              />
              <img class="img2" :src="No3.user.avatar" alt="" />
            </div>
            <div class="header-name">
              {{ No3.user.name || "虚位以待" }}
              <div class="block4">
                <Hotprice
                  :size="0.12"
                  :isWeight="false"
                  :price="No3.win || No3.total_win || 0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="award-img-box">
        <img
          src="../../assets/images/public/rank-bg2.png"
          class="play-title"
          alt=""
        />
      </div>
    </div>

    <div class="player-rank-list-box">
      <van-list>
        <van-cell v-for="(item, index) in elselist" :key="index + 'a'">
          <template #default>
            <div class="player-rank-list">
              <div class="player-info">
                <span class="player-num">{{ index + 4 }}</span>
                <div class="player-avatar">
                  <img :src="item.user?.avatar" class="play-title" alt="" />
                </div>
                <span class="player-name">{{ item.user?.name }}</span>
              </div>

              <div class="box-rank">
                <Hotprice
                  :size="0.12"
                  :isWeight="false"
                  :price="item.win || item.total_win || 0"
                />
              </div>
            </div>
          </template>
        </van-cell>
      </van-list>
    </div>

    <div class="player-rank-list-footer">
      <div class="player-rank-footer">
        <div class="player-footer-num">
          {{ userIndex + 1 }}
        </div>
        <div class="player-avatar">
          <img :src="this.user?.avatar" class="play-title" alt="" />
        </div>
        <div class="player-footer-name-box">
          <span class="player-name">
            {{ this.user?.name }}
          </span>
          <span class="player-des">
            距前一名还差{{ comparisonResult || 0 }}火力值
          </span>
        </div>
        <div class="box-rank">
          <Hotprice
            :size="0.12"
            :isWeight="false"
            :price="userinfo?.win || userinfo?.total_win || 0"
          />
        </div>
      </div>
    </div>
    <div class="tipstext" @click="showtips">活动规则</div>
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="tipsshow"
    >
      <div class="showbox">
        <div class="itembox">
          <div class="title">
            活动规则
            <img
              @click="
                () => {
                  this.tipsshow = !this.tipsshow;
                }
              "
              src="../../assets/images/new/bg7.png"
              alt=""
            />
          </div>
          <div class="line"></div>
          <div class="content">
            当日开箱最欧皇用户将获得惊喜彩蛋福利（次日0:00发放）最大欧皇将获得最大惊喜彩蛋！（积分开箱、福利宝箱除外）活动最终解释权，归TM方所有
          </div>
          <div class="btnbox">
            <div class="btn" @click="showtips">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { GetRankList } from "@/network/api.js";
import { mapState } from "vuex";
import Hotprice from "../../components/common/Hotprice.vue";
export default {
  components: { Hotprice },
  props: {
    right: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      activeName: "second", //默认选中今日
      playerLast: {},
      playerRankList: [],
      params: { type: 1 },
      differenceValue: 0,

      // 排行
      getRankingTopWTSList: [],
      userinfo: {},
      //其余排行
      elselist: [],
      showtype: "",
      restStatus: false,
      tipsshow: false,
      drawTime: null,
    };
  },
  created() {
    this.getRanking();
  },
  mounted() {},
  computed: {
    ...mapState(["user"]),
    renderList() {
      return this.getRankingTopWTSList;
    },
    No1() {
      return this.renderList[0] || { user: {} };
    },
    No2() {
      return this.renderList[1] || { user: {} };
    },
    No3() {
      return this.renderList[2] || { user: {} };
    },
    userIndex() {
      return this.getRankingTopWTSList.findIndex(
        (user) => user.user_id == this.user.id
      );
    },
    // 获取上一名
    previousUserId() {
      const index = this.userIndex;
      return index > 0 ? this.getRankingTopWTSList[index - 1].user_id : null;
    },
    // 获取上一名数据
    previousUserData() {
      const index = this.userIndex;
      return index > 0 ? this.getRankingTopWTSList[index - 1] : null;
    },
    // 与上一名进行对比
    comparisonResult() {
      const prevUserId = this.previousUserId;
      if (prevUserId !== null) {
        if (this.activeName == "third") {
          return (
            this.previousUserData.total_win - this.userinfo.total_win
          ).toFixed(2);
        } else {
          return (this.previousUserData.win - this.userinfo.win).toFixed(2);
        }
      }
    },
  },
  watch: {},
  methods: {
    updata() {
      this.restStatus = true;
      this.getRanking();
      setTimeout(() => {
        this.restStatus = false;
      }, 1000);
    },
    showtips() {
      this.tipsshow = !this.tipsshow;
    },
    handleClick(tab) {
      if (tab.name == "first") {
        this.params = { type: 2 };
      } else if (tab.name == "second") {
        this.params = { type: 1 };
      } else {
        this.params = { type: 3 };
      }
      this.getRanking();
    },
    // 获取排行榜
    async getRanking() {
      let king = await GetRankList(this.params);
      this.showtype = king.data.data.config;
      if (this.activeName == "third") {
        this.drawTime = new Date(
          this.showtype.weeks_end_time.replace(/\-/g, "/")
        ).getTime();
      } else {
        this.drawTime = new Date(
          this.showtype.day_end_time.replace(/\-/g, "/")
        ).getTime();
      }
      this.userinfo = king.data.data.user;
      this.getRankingTopWTSList = king.data.data.data;

      this.elselist = this.getRankingTopWTSList.slice(3);
    },
  },
};
</script>

<style lang="scss" scoped>
.view-RankList {
  position: relative;
  .RankList-tab-box-wrap {
    padding: 0 0.1rem;
    margin-bottom: 0.1rem;

    .RankList-tab-box {
      width: 100%;
      height: 0.24rem;
      background: url(../../assets/images/public/rank-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: bold;
      font-size: 0.2rem;
      color: #d1c1ff;
    }
  }
  .timebox {
    width: 3.35rem;
    height: 0.26rem;
    background: linear-gradient(
      90deg,
      rgba(143, 133, 211, 0) 0%,
      #5f588f 50%,
      rgba(143, 133, 211, 0) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.1rem;
    margin: 0.12rem auto;
    .timetext {
      font-weight: 400;
      font-size: 0.1rem;
      color: #ffffff;
    }
    .timenum {
      width: unset;
    }
    .timenum .con .number {
      width: unset;
      color: #fff !important;
      font-weight: 500 !important;
      font-size: 0.12rem !important;
      line-height: 0.24rem !important;
      height: 0.24rem !important;
    }
    .endtime {
      color: #fff !important;
      font-weight: 500 !important;
      font-size: 0.12rem !important;
      line-height: 0.24rem !important;
      height: 0.24rem !important;
      padding: 0 0.04rem;
    }
  }
  .avatar-box-wrap {
    position: relative;
  }
  .refsbox {
    width: 100%;
    height: 0.4rem;
    display: flex;
    justify-content: flex-end;
    //align-items: center;
    align-items: end;
    margin: 0.12rem 0;
    padding: 0 0.1rem;
    position: absolute;
    right: 0;
    top: -0.46rem;
    .refs {
      display: flex;
      align-items: center;
      img {
        width: 0.12rem;
        margin-right: 0.04rem;
      }
      .restAni {
        animation: rotate 0.5s forwards;
      }
      .reftext {
        font-weight: 500;
        font-size: 0.12rem;
        color: #c5c0ff;
      }
    }
  }
  .sort-box-top {
    width: 2.76rem;
    height: 1.1rem;
    background-size: 100% 100%;
    display: flex;
    align-content: flex-end;
    justify-content: space-between;
    margin: 0.3rem auto 0;
    .img-list {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 0.26rem;
      padding: 0 0.2rem;
      .img-item {
        width: 33.333%;
        // height: 1rem;
        text-align: center;
        font-size: 0.1rem;
        color: #ccc;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .header-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 2rem;
          font-weight: 500;
          font-size: 0.12rem;
          color: #ffffff;
        }
        .header-img {
          width: 100%;
          height: 0.7rem;
          margin: 0 auto;
          position: relative;
          margin-bottom: 0.05rem;

          img {
            width: 0.7rem;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            bottom: 0rem;
            left: 10%;
          }
          .img1 {
            z-index: 9;
          }
          .img2 {
            z-index: 8;
            width: 0.5rem;
            height: 0.5rem;
            left: 20%;
            bottom: 8%;
          }
        }
        .header-img1 {
          left: 3%;
        }
        .header-img2 {
          height: 0.7rem;
        }
        .header-img3 {
          height: 0.7rem;
        }
      }
      .img-item1 {
        width: 35%;
      }
      .img-item2,
      .img-item3 {
        width: 32.5%;
        position: relative;
        bottom: -20%;
      }
    }
  }
  .award-img-box {
    width: 2.95rem;
    height: 0.8rem;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .player-rank-list-box {
    margin: 0 auto 0.5rem;
    width: 3.2rem;
    padding-bottom: 0.5rem;
    .player-rank-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;
      .player-info {
        display: flex;
        align-items: center;
      }
      .player-num {
        height: 0.27rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 0.12rem;
        color: #ffffff;
        line-height: 0.22rem;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-right: 0.16rem;
      }
      .player-avatar {
        width: 0.43rem;
        height: 0.43rem;
        background: #d9d9d9;
        border-radius: 50%;
        margin-right: 0.16rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .box-rank {
        display: flex;
        align-items: baseline;
        font-size: 0.12rem;
        color: #f4c229;
        margin-left: 0.15rem;
        .rank-img {
          width: 0.13rem;
          height: 0.12rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .player-rank-list-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 0.85rem;
    line-height: 0.85rem;
    background: linear-gradient(135deg, #242448 0%, #42426d 100%);
    border-radius: 0.2rem 0.2rem 0rem 0rem;

    .player-rank-footer {
      display: flex;
      height: 0.59rem;
      line-height: 0.59rem;
      margin-left: 0.11rem;
      margin-top: 0.11rem;
      margin-right: 0.27rem;
      margin-bottom: 0rem;

      .player-footer-num {
        margin-right: 0.17rem;
      }
      .player-avatar {
        width: 0.53rem;
        height: 0.53rem;
        background: #d9d9d9;
        border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
        border: 0.01rem solid #756296;
        margin-right: 0.16rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .player-footer-name-box {
        height: 0.53rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 200;

        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .player-name {
          font-size: 0.12rem;
          height: 0.12rem;
          margin-bottom: 0.02rem;
        }
        .player-des {
          font-size: 0.12rem;
        }
      }
      .box-rank {
        display: flex;
        align-items: baseline;
        font-size: 0.12rem;
        color: #f4c229;
        margin-left: 0.1rem;
        .rank-img {
          width: 0.13rem;
          height: 0.12rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .tipstext {
    width: 0.24rem;
    height: 0.64rem;
    background: linear-gradient(
      270deg,
      #8f85d3 20%,
      rgba(143, 133, 211, 0.2) 100%
    );
    position: fixed;
    left: 0;
    top: 30%;
    font-weight: 500;
    font-size: 0.12rem;
    color: #ffffff;
    text-align: center;
    writing-mode: vertical-rl;
    padding: 0.05rem;
    opacity: 0.85;
    border-radius:0 0.08rem 0.08rem 0;
  }
  .showbox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .itembox {
      width: 3.35rem;
      border: 0.01rem solid #5d5d9b;
      background: #08081e;
      .title {
        width: 100%;
        height: 0.38rem;
        text-align: center;
        line-height: 0.38rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.16rem;
        color: #dfdbff;
        position: relative;
        img {
          position: absolute;
          right: 0.2rem;
          top: 0.1rem;
          width: 0.12rem;
        }
      }
      .line {
        width: 100%;
        border: 0.01rem solid;
        border-image: linear-gradient(
            90deg,
            rgba(93, 93, 155, 0),
            rgba(93, 93, 155, 1),
            rgba(93, 93, 155, 0)
          )
          1 1;
      }
      .content {
        padding: 0.2rem;
        margin: 0 auto;
        font-weight: 300;
        font-size: 0.12rem;
        color: rgba(191, 191, 237, 0.5);
      }

      .sethead {
        width: 100%;
        height: 0.66rem;
        display: flex;
        margin-bottom: 0.15rem;
        .headimg {
          width: 1.08rem;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .headimgbox {
            width: 0.66rem;
            height: 0.66rem;
            border-radius: 50%;
            border: 0.01rem solid #5d5d9b;
            padding: 0.06rem;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .seticon {
            position: absolute;
            right: 0.26rem;
            bottom: 0rem;
            width: 0.16rem;
            border: 0;
            padding: 0;
            border-radius: unset;
          }
        }
        .setinfobox {
          width: 1.84rem;
          height: 100%;
          .list1 {
            display: flex;
            align-items: center;
            margin-bottom: 0.14rem;
            span {
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 0.12rem;
              color: #dfdbff;
              margin-right: 0.09rem;
            }
            .nikename {
              padding: 0 0.1rem;
              height: 0.17rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 0.12rem;
              color: #9894b4;
              border-bottom: 0.01rem solid;
              border-image: linear-gradient(
                  90deg,
                  rgba(93, 93, 155, 0),
                  rgba(93, 93, 155, 1),
                  rgba(93, 93, 155, 0)
                )
                1 1;
            }
            .seticon {
              width: 0.16rem;
              margin-left: 0.12rem;
            }
          }
        }
      }
      .setphonebox {
        width: 2.54rem;
        margin: 0 auto;
        .msgsettitle {
          width: 100%;
          height: 0.44rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .titlebg {
            width: 0.74rem;
            height: 0.18rem;
            background: url("../../assets/images/Roll/room/mb-roll-title.png")
              no-repeat;
            background-size: 100% 100%;
            text-align: center;
            line-height: 0.18rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 0.12rem;
            color: #ffffff;
          }
        }
        .list2 {
          display: flex;
          align-items: center;
          margin-bottom: 0.14rem;
          span {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 0.12rem;
            color: #dfdbff;
            margin-right: 0.09rem;
          }
          .nikename {
            width: 1.6rem;
            height: 0.17rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 0.12rem;
            text-align: center;
            color: #9894b4;
            border-bottom: 0.01rem solid;
            border-image: linear-gradient(
                90deg,
                rgba(93, 93, 155, 0),
                rgba(93, 93, 155, 1),
                rgba(93, 93, 155, 0)
              )
              1 1;
          }
          .seticon {
            width: 0.16rem;
            margin-left: 0.12rem;
          }
        }
      }
      .tipsbox {
        display: flex;
        flex-wrap: wrap;
        width: 3rem;
        margin: 0 auto;
        .tipsitem {
          padding: 0.02rem 0.13rem;
          background: linear-gradient(135deg, #282845 100%, #12122f 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.12rem;
          color: #606093;
          margin: 0.05rem 0.11rem;
        }
      }
      .putbox {
        width: 2.25rem;
        .stitle {
          font-family: PingFang SC, PingFang SC;
          font-weight: 300;
          font-size: 0.1rem;
          color: #dfdbff;
          margin: 0.12rem 0;
        }
      }
      .btnbox {
        width: 100%;
        margin: 0.15rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          width: 0.9rem;
          height: 0.26rem;
          text-align: center;
          line-height: 0.26rem;
          background: linear-gradient(135deg, #242448 0%, #42426d 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.14rem;
          color: #ffffff;
        }
      }
    }
  }
}
:deep(.el-tabs__nav-scroll) {
  display: flex;
  justify-content: space-evenly;
  font-size: 0.21rem;
}
:deep(.el-tabs__item) {
  color: #d3d3d3;
}
:deep(.el-tabs__item.is-active) {
  color: #c5bbed;
}
:deep(.el-tabs__item:hover) {
  color: #c5bbed;
}

:deep(.el-tabs__nav-wrap:after) {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background-color: var(--el-border-color-light);
  z-index: var(--el-index-normal);
}

:deep(.el-tabs__active-bar) {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background-color: var(--el-border-color-light);
  z-index: var(--el-index-normal);
  background-color: #c5bbed;
  color: #c5bbed;
}
:deep(.van-list) {
  background-color: #020715;
  //opacity: 0.5;
}
:deep(.van-cell::after) {
  border-bottom: 0;
}
:deep(.van-cell) {
  width: 100%;
  background-color: #211c2c;
  border-radius: 0.03rem 0.03rem 0.03rem 0.03rem;
  border: 0.01rem solid #756296;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 0.12rem;
  color: #ffffff;
  margin-bottom: 0.11rem;
  text-decoration: none;
  padding: 4px 10px;
}
</style>
